@import "misc";
@import "svg-icons";
@charset "utf-8";
@import url('https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700');
@import url('https://fonts.googleapis.com/css?family=Anonymous+Pro:400,700');

// global {[(

body {
	background-color: var(--background-color);
	color: var(--base-color);
	font-family: $base-font-family;
	font-size: $base-font-size;
	line-height: $base-line-height !important;
	margin: 0 auto;
	padding: 0;
	text-align: left;
}

h1, h2, h3, h4, h5, h6 {
	color: var(--base-color);
	font-weight: bold;
	margin: 0px;
	padding: 0px;
}

h1 { font-size: 1.5 * $base-font-size; margin-top: 1.5 * $base-margin-top; }
h2 { font-size: 1.4 * $base-font-size; margin-top: 1.4 * $base-margin-top; }
h3 { font-size: 1.3 * $base-font-size; margin-top: 1.3 * $base-margin-top; }
h4 { font-size: 1.2 * $base-font-size; margin-top: 1.2 * $base-margin-top; }
h5 { font-size: 1.1 * $base-font-size; margin-top: 1.1 * $base-margin-top; }
h6 { font-size: 1.0 * $base-font-size; margin-top: 1.0 * $base-margin-top; }

p, ul, ol {
	color: var(--base-color);
	margin: 15px 0;
}

a {
	color: var(--link-color);
	cursor: pointer;
	text-decoration: underline;
	&:hover, &:active {
		background-color: var(--base-color);
		color: var(--background-color);
	}
}

em, i {
	font-style: italic;
}

strong, b {
	font-weight: bold;
}

// )]} global

// layout / sections {[(

footer {
	border-top: var(--border);
	color: var(--background-color);
	margin-top: 25px;
	margin: 25px auto;
	padding: 20px 0;
	text-align: center;
	a, a:hover, a:active {
		background-color: var(--background-color);
	}
}

header {
	align-items: center;
	border-bottom: var(--border);
	border-top: var(--border);
	display: grid;
	grid-template:
		"avatar title nav" auto
		"avatar sub   nav" auto / auto 1fr auto
	;
	margin-bottom: 25px;
	margin-top: 25px;
	padding: 10px 0;
	a, a:hover, a:active {
		background-color: var(--background-color);
		color: var(--base-color);
	}
}

header > h1 {
	cursor: pointer;
	grid-area: title;
	margin: 0;
	a {
		text-decoration: none
	}
}

header > nav {
	grid-area: nav;
	margin-bottom: 25px;
	margin-top: 25px;
	padding-left: 0px;
	a {
		color: var(--base-color);
		display: inline;
		text-align: right;
		text-decoration: none;
		&:hover {
			background-color: var(--base-color);
			color: var(--background-color);
			text-decoration: none;
		}
	}
}

header > h5 {
	grid-area: sub;
}

header > a {
	grid-area: avatar;
	display: block;
	margin-right: 25px;
}

.container {
	margin: auto;
	max-width: $container-max-width;
	width: $container-width;
}

.site-avatar {
	width: 75px;
}

.epigraph {
	padding: 0 5% 0 45%;
	font-style: italic;
	color: var(--code-color-10);
	.text {
		color: inherit;
	}
	.author {
		color: inherit;
		text-align: right;
	}
}

.posts > .post {
	border-bottom: var(--border);
	padding-bottom: 2em;
	&:last-child {
		border-bottom: none;
		padding-bottom: 0px;
	}
}

.post blockquote {
	border-left: 1px solid;
	font-size: $base-font-size;
	margin: 1.5em .5em;
	padding: .1em 1.5em;
}

.post .date {
	color: var(--code-color-10);
	padding-top: 40px;
}

.footnote {
	font-size: smaller;
	text-decoration: none;
	vertical-align: super;
	&:before { content: "["; }
	&:after  { content: "]"; }
}

// )]} layout / sections

// tags {[(

#tags-list {
	list-style-type: none;
	padding-left: 5px;
}

.tag-entry {
	font-size: 1.3 * $base-font-size;
	font-weight: bold;
}

.toggle {
	display: none;
}

.label-toggle:hover {
	background-color: var(--base-color);
	color: var(--background-color);
	cursor: pointer;
}

.collapsible-content {
	max-height: 0px;
	overflow: hidden;
}

.toggle:checked + .label-toggle + .collapsible-content {
	max-height: 25em;
}

.tag-posts-list {
	list-style-type: disc;
	padding-left: 25px;
}

.tag-post-entry {
	font-size: $base-font-size;
	font-weight: normal;
}

// )]} tags

// lists {[(

ul {
	list-style-type: disc;
	padding-left: 30px;
	ul {
		list-style-type: circle;
		margin: 0;
	}
	ol {
		margin: 0;
	}
}

ol {
	list-style-type: decimal;
	padding-left: 30px;
	ul {
		list-style-type: circle;
		margin: 0;
	}
	ol {
		margin: 0;
	}
}

// )]} lists

// selection highlight {[(

::selection {
	background: var(--selection-background);
	color: var(--selection-text);
}

::-moz-selection {
	background: var(--selection-background);
	color: var(--selection-text);
}

// )]} selection highlight

@import "highlights";

// vim: fmr={[(,)]}
