// code and syntax highlighting {[(

code {
	font-family: 'Anonymous Pro', 'Ubuntu Mono', monospace;
	font-size: 14px;
}

.lineno {
	color: var(--code-color-1);
	margin-right: 15px;
}

figure.highlight {
	margin: 5px 0;
}

pre {
	background-color: var(--background-color);
	border: var(--border);
	color: var(--text-color);
	font-family: $base-font-family !important;
	font-size: $base-font-size;
	font-size: $base-font-size;
	line-height: 1.5 !important;
	margin: 20px 0 20px 0;
	overflow: auto;
	padding: 10px;
}

.highlight .bp { color: var(--code-color-13); } // Name.Builtin.Pseudo
.highlight .c { color: var(--code-color-1); font-style: italic } // Comment
.highlight .c1 { color: var(--code-color-1); font-style: italic } // Comment.Single
.highlight .cm { color: var(--code-color-1); font-style: italic } // Comment.Multiline
.highlight .cp { color: var(--code-color-6); } // Comment.Preproc
.highlight .cs { color: var(--code-color-5); font-style: italic } // Comment.Special
.highlight .err { color: var(--code-color-3); background-color: var(--code-color-4); } // Error
.highlight .g { color: var(--code-color-1); } // Generic
.highlight .gd { color: var(--code-color-7); } // Generic.Deleted
.highlight .ge { color: var(--code-color-10); font-style: italic } // Generic.Emph
.highlight .gh { color: var(--code-color-8); font-weight: bold } // Generic.Heading
.highlight .gi { color: var(--code-color-9); } // Generic.Inserted
.highlight .go { color: var(--code-color-10); } // Generic.Output
.highlight .gp { color: var(--code-color-11); } // Generic.Prompt
.highlight .gr { color: var(--code-color-7); } // Generic.Error
.highlight .gs { color: var(--code-color-10); font-weight: bold } // Generic.Strong
.highlight .gt { color: var(--code-color-17); } // Generic.Traceback
.highlight .gu { color: var(--code-color-12); font-weight: bold } // Generic.Subheading
.highlight .hll { background-color: var(--code-color-2); }
.highlight .il { color: var(--code-color-14); } // Literal.Number.Integer.Long
.highlight .k { color: var(--code-color-5); } // Keyword
.highlight .kc { color: var(--code-color-5); } // Keyword.Constant
.highlight .kd { color: var(--code-color-5); } // Keyword.Declaration
.highlight .kn { color: var(--code-color-5); } // Keyword.Namespace
.highlight .kp { color: var(--code-color-5); } // Keyword.Pseudo
.highlight .kr { color: var(--code-color-5); } // Keyword.Reserved
.highlight .kt { color: var(--code-color-13); } // Keyword.Type
.highlight .l { color: var(--code-color-10); } // Literal
.highlight .ld { color: var(--code-color-7); } // Literal.Date
.highlight .m { color: var(--code-color-14); } // Literal.Number
.highlight .mf { color: var(--code-color-14); } // Literal.Number.Float
.highlight .mh { color: var(--code-color-14); } // Literal.Number.Hex
.highlight .mi { color: var(--code-color-14); } // Literal.Number.Integer
.highlight .mo { color: var(--code-color-14); } // Literal.Number.Oct
.highlight .n { color: var(--code-color-11); } // Name
.highlight .na { color: var(--code-color-16); } // Name.Attribute
.highlight .nb { color: var(--code-color-13); } // Name.Builtin
.highlight .nc { color: var(--code-color-9); text-decoration: underline } // Name.Class
.highlight .nd { color: var(--code-color-11); } // Name.Decorator
.highlight .ne { color: var(--code--color-17); } // Name.Exception
.highlight .nf { color: var(--code-color-9); } // Name.Function
.highlight .ni { color: var(--code-color-11); font-weight: bold } // Name.Entity
.highlight .nl { color: var(--code-color-7); } // Name.Label
.highlight .nn { color: var(--code-color-13); text-decoration: underline } // Name.Namespace
.highlight .no { color: var(--code-color-7); } // Name.Constant
.highlight .nt { color: var(--code-color-16); font-weight: bold } // Name.Tag
.highlight .nv { color: var(--code-color-7); } // Name.Variable
.highlight .nx { color: var(--code-color-11); } // Name.Other
.highlight .o { color: var(--code-color-11); } // Operator
.highlight .ow { color: var(--code-color-5); } // Operator.Word
.highlight .p { color: var(--code-color-11); } // Punctuation
.highlight .py { color: var(--code-color-11); } // Name.Property
.highlight .s { color: var(--code-color-9); } // Literal.String
.highlight .s1 { color: var(--code-color-15); } // Literal.String.Single
.highlight .s2 { color: var(--code-color-15); } // Literal.String.Double
.highlight .sb { color: var(--code-color-15); } // Literal.String.Backtick
.highlight .sc { color: var(--code-color-15); } // Literal.String.Char
.highlight .sd { color: var(--code-color-15); } // Literal.String.Doc
.highlight .se { color: var(--code-color-15); } // Literal.String.Escape
.highlight .sh { color: var(--code-color-15); } // Literal.String.Heredoc
.highlight .si { color: var(--code-color-15); } // Literal.String.Interpol
.highlight .sr { color: var(--code-color-14); } // Literal.String.Regex
.highlight .ss { color: var(--code-color-5); } // Literal.String.Symbol
.highlight .sx { color: var(--code-color-15); } // Literal.String.Other
.highlight .vc { color: var(--code-color-7); } // Name.Variable.Class
.highlight .vg { color: var(--code-color-7); } // Name.Variable.Global
.highlight .vi { color: var(--code-color-7); } // Name.Variable.Instance
.highlight .w  { color: var(--code-color-18); } // Text.Whitespace
.highlight .x { color: var(--code-color-10); } // Other

// )]} code and syntax highlighting

// vim: fmr={[(,)]}
