/**
 * Light theme variables
 */

:root {
  --background-color: #FFF;
  --base-color: #000;
  --border: dashed 1px rgba(0, 0, 0, 1);
  --link-color: var(--base-color);
  --placeholder-color: var(--base-color);
  --primary-color: #0000EE;
  --selection-background: rgba(0, 0, 0, 0.99);
  --selection-text: #FFF;
  --text-color: var(--base-color);
  --code-color-1: #aaaaaa;
  --code-color-2: #ffffcc;
  --code-color-3: #F00000;
  --code-color-4: #F0A0A0;
  --code-color-5: #0000aa;
  --code-color-6: #4c8317;
  --code-color-7: #aa0000;
  --code-color-8: #000080;
  --code-color-9: #00aa00;
  --code-color-10: #888888;
  --code-color-11: #555555;
  --code-color-12: #800080;
  --code-color-13: #00aaaa;
  --code-color-14: #009999;
  --code-color-15: #aa5500;
  --code-color-16: #1e90ff;
  --code-color-17: #800000;
  --code-color-18: #bbbbbb;
}
