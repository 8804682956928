// variables {[(

$base-font-family:	'Ubuntu Mono', monospace !default;
$base-font-size:	16px !default;
$base-line-height:	1.5 !default;
$base-margin-top:	12px !default;
$container-max-width:	740px !default;
$container-width:	100% !default;

// )]} variables

// meyer reset {[(

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p,
blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img,
ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i,
center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section,
summary, time, mark, audio, video {
	border: 0;
	font-size: 100%;
	font: inherit;
	margin: 0;
	padding: 0;
	vertical-align: baseline;
}

// HTML5 display-role reset for older browsers
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav,
section {
	display: block;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

// Apply a natural box layout model to all elements from:
// http://www.paulirish.com/2012/box-sizing-border-box-ftw/
*, *:before, *:after {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}

// )]} meyer reset

// vim: fmr={[(,)]}
